import React from "react";
import "./Photos.css";
import TeamInfofrom from "../../store/TeamInfo.js";
import TeamChild from './Photo/Photo.jsx'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Photos = () => {
  let teamElements = TeamInfofrom.map((teamItem, i) => {
    return <TeamChild key={`team-photo-${i}`} team={teamItem} />;
  });

  return (
    <section className="photos_wrap red_grad">
      <div className="container photos">
        <h2 className='photos_title'>Media gallery</h2>
        <div className="photos--pc team">{teamElements}</div>
        <Carousel className="photos_slider" infiniteLoop={true} showThumbs={false} showStatus={false} swipeable={false} emulateTouch={false} centerMode={true} centerSlidePercentage={90}>
          {teamElements}
        </Carousel>
      </div>
    </section>
  );
};
export default Photos;
