let TeamInfo = [
  {
    id: 1,
    teamAlt: "Stephy Hogan tells about S.C.H.I.T. on the UI/UX Global Summit'22.",
    teamImg: require("../img/Gallery_1.jpg").default,
  },
  {
    id: 2,
    teamAlt: "Marcos Bérgamo, Abdullah Ansari, Daniel Castro, Alexander Lichter, Viktor and Daniel Afonso are on the JavaScript Global Summit'22.",
    teamImg: require("../img/Gallery_2.jpg").default,
  },
  {
    id: 4,
    teamAlt: "Elaine Gómez tells about a timeline on the Game Development Global Summit'21.",
    teamImg: require("../img/Gallery_4.png").default,
  },
  {
    id: 5,
    teamAlt: "Speakers answer the question 'Marcell Kiss, So in case, when the url contains an id, or something like that, which has effect on the full page, than this is not the best solution?' on the Angular Global Summit'22.",
    teamImg: require("../img/Gallery_5.jpg").default,
  },
  {
    id: 3,
    teamAlt: "@m4d_z (mads) tells about CSS Modules on the <React Global> Online Summit 2022.",
    teamImg: require("../img/Gallery_3.jpg").default,
  },
  {
    id: 6,
    teamAlt: "Speakers answer the question 'From a social and technologically-dependent perspective, the metaverse has been called a potential dystopia. What are your thoughts on this calm?' on the UI/UX Global Summit'22.",
    teamImg: require("../img/Gallery_6.jpg").default,
  },
];

export default TeamInfo;
