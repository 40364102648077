import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import React from 'react';
import './App.css';
import Header from './components/Header/Header.jsx';
import Numbers from './components/Numbers/Numbers.jsx';
import Photos from './components/Photos/Photos.jsx';
import SpeakersFrom from './components/Speakers from/Speakers from.jsx';
import Main from './components/main/main.jsx';
// import Workshops from './components/Workshops/Workshops.jsx';
// import Speakers from './components/Speakers/Speakers.jsx';
// import Packages from './components/Packages/Packages.jsx';
import BecomeSpeaker from './components/BecomeSpeaker/BecomeSpeaker';
import PackagesWrap from './components/PackagesWrap/PackagesWrap.jsx';
// import Sponsors from "./components/Sponsors/Sponsors";
import Audience from './components/Audience/Audience';
import Mission from './components/Mission/Mission';
import Reviews from './components/Reviews/Reviews.jsx';
import RunningString from './components/Running_string/Running_string';
import Speakers from './components/Speakers/Speakers.jsx';
import Footer from './components/footer/Footer.jsx';
// import Quote from './components/Quote/Quote';
import TagCloud from './components/TagCloud/TagCloud';
// import PC from './components/PC/PC';
import CountDown from './components/CountDown1/CountDown.jsx';
import GoToPackages from './components/GoToPackages/GoToPackages';
import LatestProduct from './components/LatestProduct/LatestProduct';
import Packages from './components/Packages2/Packages.jsx';
import Sponsorship from './components/Sponsorship/Sponsorship';

const App = props => {
	return (
		<main>
			<Header />
			{/* <GiftCard /> */}
			<Main />
			{/* <Banner /> */}
			{/* <Quote /> */}
			<Numbers />
			<LatestProduct />
			<SpeakersFrom />
			<Audience />
			{/* <EventAbout /> */}
			<GoToPackages />
			<Speakers />
			<RunningString />
			{/* <Agenda /> */}
			{/* <AIPanel /> */}
			{/* <Workshops /> */}
			<Packages />
			<TagCloud />
			<PackagesWrap />
			{/* <AskCompany /> */}
			{/* <Enterprise /> */}
			<BecomeSpeaker />
			{/* <PC /> */}
			{/* <Partners /> */}
			<Sponsorship />
			{/* <Sponsors /> */}
			<Reviews />
			<Photos />
			{/* <Team /> */}
			<Mission />
			<TawkMessengerReact
				propertyId='62d53950b0d10b6f3e7ce13c'
				widgetId='1g92ed1m4'
			/>
			<Footer />
			<CountDown />
		</main>
	);
};

export default App;
