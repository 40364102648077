import React, { useState, useEffect } from 'react';
import './Form.css'
import close_popup from '../../../img/close_popup.svg'
import { send } from 'emailjs-com';

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [linkError, setLinkError] = useState(true);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                default:
            }
        }
    }, [value, validations]);

    useEffect(() => {
        if (isEmpty) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }

    }, [isEmpty])

    return {
        isEmpty
    }
}

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    const resetField = () => {
        setValue('');
        setDirty(false);
    }

    return {
        value,
        onChange,
        resetField,
        onBlur,
        isDirty,
        ...valid
    }
}

const Form = () => {
    const name = useInput('', { isEmpty: true });
    const company = useInput('', { isEmpty: true });
    const email = useInput('', { isEmpty: true });
    const [leads, setLeads] = useState('');
    const [boost, setBoost] = useState('');
    const [devs, setDevs] = useState('');
    const [letterSucces, setLetterSucces] = useState('');
    const [textErr, setTextErr] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const data = {
        from_name: name.value,
        company: company.value,
        email: email.value,
        leads: leads === true ? 'Yes' : 'No',
        boost: boost === true ? 'Yes' : 'No',
        devs: devs === true ? 'Yes' : 'No',
        event: `QA Global Summit'24 Vol.2`,
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        send(
            'service_ltti5e7',
            'template_uo768rf',
            data,
            'TnFEX3k82W8nPRUaw'
        )
            .then((response) => {
                setLetterSucces(true);
            })
            .catch((err) => {
                setLetterSucces(false);
                setTextErr(err);
            });
        setShowPopup(true);
        name.resetField();
        company.resetField();
        email.resetField();
    }

    return (
        <>
            <form className='sponsorship_form' onSubmit={onSubmit}>
                <p className='sponsorship_form--undertitle'>Get proposal</p>
                <div className='sponsorship_form--block'>
                    <input placeholder='First name*' onChange={e => name.onChange(e)} onBlur={e => name.onBlur(e)} className={`sponsorship_form--input ${(name.isDirty && name.isEmpty) && 'error'}`} value={name.value} type="text" name="from_name" />
                    <input placeholder='Company*' onChange={e => company.onChange(e)} onBlur={e => company.onBlur(e)} className={`sponsorship_form--input ${(company.isDirty && company.isEmpty) && 'error'}`} value={company.value} type="text" name="company" />
                    <input placeholder='E-mail*' onChange={e => email.onChange(e)} onBlur={e => email.onBlur(e)} className={`sponsorship_form--input ${(email.isDirty && email.isEmpty) && 'error'}`} value={email.value} type="email" name="email" />
                    <p className='sponsorship_form--undertitle'>Your sponsorship goals</p>
                    <div className='sponsorship_form--checkbox'>
                        <input className='sponsorship_form--checkbox_input' type="checkbox" id="leads" name="interest" onChange={() => setLeads(!leads)} value={leads} />
                        <label className={`sponsorship_form--checkbox_label p1 ${leads === true && 'active'}`} for="leads">Gather qualified leads</label>
                    </div>
                    <div className='sponsorship_form--checkbox'>
                        <input className='sponsorship_form--checkbox_input' type="checkbox" id="boost" name="interest" onChange={() => setBoost(!boost)} value={boost} />
                        <label className={`sponsorship_form--checkbox_label p1 ${boost === true && 'active'}`} for="boost">Boost brand awareness</label>
                    </div>
                    <div className='sponsorship_form--checkbox'>
                        <input className='sponsorship_form--checkbox_input' type="checkbox" id="devs" name="interest" onChange={() => setDevs(!devs)} value={devs} />
                        <label className={`sponsorship_form--checkbox_label p1 ${devs === true && 'active'}`} for="devs">Hire new developers</label>
                    </div>
                </div>
                <input disabled={name.isEmpty || company.isEmpty || email.isEmpty} className='form_btn button' type="submit" value="get proposal" />
            </form>
            {
                (letterSucces === true || letterSucces === false) && showPopup === true && <div className='form_popup form_message green' onClick={() => setShowPopup(false)}>
                    <div className='form_popup-modal'>
                        <img src={close_popup} className="form_popup-close" onClick={() => setShowPopup(false)} />
                        <p className='p1'>{letterSucces === true ? 'The form has been sent.' : `The email you sent could not be delivered. Error: ${textErr}`}</p>
                    </div>
                </div>
            }
        </>
    );
};

export default Form;