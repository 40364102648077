import React from "react";

const SocialMedia = ({ speaker2, item, hideSocials }) => {
    return (
        <>
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M22.5558 0.980469H0.444092V16.6209H5.97201L11.4999 20.5093L17.0279 16.6209H22.5558V0.980469ZM14.1478 6.47514C14.1478 7.93807 12.9629 9.12297 11.4999 9.12297C10.037 9.12297 8.85211 7.93807 8.85211 6.47514C8.85211 5.01221 10.037 3.82731 11.4999 3.82731C12.9629 3.82731 14.1478 5.01221 14.1478 6.47514ZM6.20428 12.4328C6.20428 10.672 9.73252 9.78493 11.4999 9.78493C13.2674 9.78493 16.7956 10.672 16.7956 12.4328V13.7567H6.20428V12.4328Z" fill="#A7A7A7" />
            </svg>
            <span className='speaker_menu--text'>Follow</span>
            {(hideSocials.indexOf(item.fname_text + item.lname_text) > -1) && <div className='speaker_open-menu'>
                {item.twitter_handle_text && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={item.twitter_handle_text}></a>}
                {item.website_github_text && <a className='social_link social_github' target='_blank' rel="noreferrer" href={item.website_github_text}></a>}
                {item.linkedin_text && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.linkedin_text}></a>}
                {item.website_fb_text && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={item.website_fb_text}></a>}
                {item.website_other_text && <a className='social_link social_website' target='_blank' rel="noreferrer" href={item.website_other_text}></a>}
                {item.website_youtoube_text && <a className='social_link social_yb' target='_blank' rel="noreferrer" href={item.website_youtoube_text}></a>}

                {speaker2.name !== '' && //condition if we have 2 speakers for 1 topic
                    <div className='social_speaker2'>
                        {speaker2.tw && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={speaker2.tw}></a>}
                        {speaker2.github && <a className='social_link social_github' target='_blank' rel="noreferrer" href={speaker2.github}></a>}
                        {speaker2.ln && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={speaker2.ln}></a>}
                        {speaker2.website && <a className='social_link social_website' target='_blank' rel="noreferrer" href={speaker2.website}></a>}
                        {speaker2.fb && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={speaker2.fb}></a>}
                        {speaker2.yb && <a className='social_link social_yb' target='_blank' rel="noreferrer" href={speaker2.yb}></a>}
                    </div>
                }
            </div>}
        </>
    );
}

export default SocialMedia;
