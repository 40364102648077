import React from 'react';
import './Sponsorship.css';
import dec_img from '../../img/sponsorship/img.png'
import Form from './Form/Form'

const Sponsorship = () => {
    const state = {
        Sponsorship_item: [
            {
                text: 'Social media boost',
            },
            {
                text: 'Sponsored Product Emails',
            },
            {
                text: 'Dedicated hands-on workshops',
            },
            {
                text: 'Keynote Presentation',
            },
            {
                text: 'and 15 additional perks',
            },
        ],
    };

    const renderItem = (item, i) => {
        return (
            <li className="sponsorship_list--item p1" key={`sponsorship_list--item-${i}`}>{item.text}</li>
        );
    };

    return (
        <div className="sponsorship_wrap" id='sponsorship'>
            <div className="container sponsorship">
                <h2>For sponsors</h2>
                <div className="sponsorship_content">
                    <div className='sponsorship_info'>
                        <img className="sponsorship_img" src={dec_img} alt="" />
                        <div className='sponsorship_text--block'>
                            <p className="sponsorship_text p1">Promote your brand and products during this Summit to audience of thousands of software developers from across the globe.</p>
                            <ul className="sponsorship_list">
                                {state.Sponsorship_item.map((item, i) => renderItem(item, i))}
                            </ul>
                        </div>
                    </div>
                    <Form />
                </div>
            </div>
        </div>
    );
};

export default Sponsorship;