import React from 'react';
import Marquee from 'react-double-marquee';
import { Link } from 'react-scroll';
import CopyBtn from './copyBtn/copyBtn';
import './main.css';
import qa_logo from '../../img/main/main_img.png';
import aws from '../../img/main/logos/aws.svg';
import microsoft from '../../img/main/logos/Microsoft.svg';
import ibm from '../../img/main/logos/ibm.svg';
import epam from '../../img/main/logos/epam.svg';
import meta from '../../img/main/logos/meta.svg';
import paypal from '../../img/main/logos/paypal.svg';
import adobe from '../../img/main/logos/adobe.svg';
import accenture from '../../img/main/logos/Accenture.svg';
import codemateai from '../../img/main/logos/codemateai.svg';
import nvidia from '../../img/main/logos/nvidia.svg';
import intel from '../../img/main/logos/intel.svg';
import netflix from '../../img/main/logos/Netflix.svg';

const speakers = [
	aws,
	microsoft,
	ibm,
	epam,
	meta,
	paypal,
	adobe,
	accenture,
	codemateai,
	nvidia,
	intel,
	netflix,
];

export default class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstScreenHeight: document.documentElement.clientHeight,
		};

		this.changeScreenHeight = this.changeScreenHeight.bind(this);
	}

	changeScreenHeight() {
		this.setState({
			firstScreenHeight: document.documentElement.clientHeight,
		});

		document.querySelector('.main_wrap').style.height =
			this.state.firstScreenHeight + 'px';
	}

	componentWillMount = () => {
		window.addEventListener('resize', this.changeScreenHeight);
		return () => window.removeEventListener('resize', this.changeScreenHeight);
	};

	componentDidMount() {
		this.changeScreenHeight();
	}

	render() {
		return (
			<div className='main_wrap' id='home'>
				{/* <div className='main_bg'></div> */}
				<div className='container main_block'>
					<div className='main_content'>
						<h1 className='main_title'>
							<span className='main_title--blue'>QA</span> Global
							<span className='main_title--plashka main_title--small'>
								{' '}
								Summit'25
							</span>
						</h1>
						{/* <div className='main_title_img_block'>
                            <img className='main_title_img main_title_img--pc' src={title} alt="" />
                            <img className='main_title_img main_title_img--mob' src={title} alt="" />
                        </div> */}
						{/* <div className='main_img_block--mob'>
							<img className='main_img' src={creativeMob} alt='' />
						</div> */}
						<div className='main_dates'>
							<p>January 21 – Junior Day</p>
							<p>January 22 – Senior Day</p>
							<p>February 4 – AI Agents Day</p>
						</div>
						<p className='main_descr'>Online Summit for QA Engineers</p>
						{/* <p className='newFormat'>New Format Micro Workshops</p> */}
						<div className='main_aiDay'>
							<p>{'{ +AI Agents Day }'}</p>
						</div>

						<div className='main_btns_wrap'>
							<Link
								activeClass='active'
								to='packages'
								spy={true}
								smooth={true}
								offset={80}
								className='main_btn button'
							>
								Register
							</Link>
							{/* <Link
								activeClass='active'
								to='tickets'
								spy={true}
								smooth={true}
								offset={80}
								className=' button main_btn_share'
							>
								GET TICKETS
							</Link> */}
							{/* <a rel='norefferer' target='_blank' href="https://geekle.us/stage_/qa23" className='button main_btn_share'>Watch now</a> */}
							<CopyBtn />
						</div>
					</div>
					<div className='main_speakers'>
						<h3 className='main_speakers-title'>Speakers in our community</h3>
						<div className='main_speakers-logos'>
							{speakers.map(speaker => {
								return (
									<img src={speaker} alt={`${speaker}_logo`} key={speaker} />
								);
							})}
						</div>
						<img src={qa_logo} alt='qa' />
					</div>
					{/* <div className='main_img_block main_img_block--pc'>
						<img className='main_img' src={creative} alt='' />
					</div> */}
				</div>
				<div
					className='summit_wrap'
					style={{
						width: '100%',
						whiteSpace: 'nowrap',
					}}
				>
					<Marquee direction='left' speed={0.05} delay={0} childMargin={0}>
						<span className='style_one'>QA Global Summit’25</span>
						<span className='style_two'>QA Global Summit’25</span>
						<span className='style_one'>QA Global Summit’25</span>
						<span className='style_two'>QA Global Summit’25</span>
						<span className='style_one'>QA Global Summit’25</span>
						<span className='style_two'>QA Global Summit’25</span>
					</Marquee>
				</div>
			</div>
		);
	}
}
