import React, { useEffect, useState } from 'react';
import Popup from '../../../Popup/Popup.jsx';
import '../VideoPresent.css';
import ShareLanding from './ShareLanding/ShareLanding';
import SocialMedia from './SocialMedia/SocialMedia';
import Calendars from './Calendars/Calendars'
// import PopupMore from './Popup/Popup'

const SpeakerItem = ({
	trackTitle,
	dateEnd,
	dateStart,
	pageURL,
	eventTitle,
	toggleSocials,
	toggleShares,
	toggleCalendars,
	hideSocials,
	hideShares,
	hideCalendars,
	hideSpeakers,
	item,
	i,
	track,
	speakers,
	agenda,
}) => {
	const [speaker2, setSpeaker2] = useState({
		name: '',
		position: '',
		logo: '',
		company: '',
		ln: '',
		tw: '',
		github: '',
		website: '',
		fb: '',
		yb: '',
	});

	useEffect(() => {
		if (item.performancespeaker_list_custom_speaker) {
			//search for second speaker for current topic
			if (item.performancespeaker_list_custom_speaker.length > 1) {
				speakers.map(speaker => {
					if (speaker._id === item.performancespeaker_list_custom_speaker[0]) {
						//check if id of speaker are in the list of speakers
						setSpeaker2({
							name: speaker.fname_text + ' ' + speaker.lname_text,
							position: speaker.position_text,
							logo: speaker.companylogo_image,
							company: '',
							ln: speaker.linkedin_text,
							tw: speaker.twitter_handle_text,
							github: speaker.website_github_text,
							website: speaker.website_other_text,
							fb: speaker.website_fb_text,
							yb: speaker.website_youtoube_text,
						});
					}
				});
			}
		}
	}, []);

	return (
		item.performancespeaker_list_custom_speaker !== undefined &&
		item.type_option_scheduletype === 'Performance' &&
		item.performancespeakerstar_boolean === true && (
			<React.Fragment>
				<div
					className={`${!hideSpeakers ? 'active' : ''} speaker_item`}
					style={{ order: item.performancespeakernum_number }}
				>
					<div
						className={`speaker_track ${track === 'senior' ? 'senior' : ''}`}
						style={{
							borderColor:
								track !== 'junior' ? agenda.agendamarkercolor_text : '#C9C9C9',
						}}
					>
						{trackTitle} {track === 'junior' ? '🤓' : '🔥'}
					</div>
					<div className='speaker_info'>
						<p className='speaker_name p1'>
							{item.fname_text} {item.lname_text}
						</p>
						<p className='speaker_position grey p2'>{item.position_text}</p>
						<img
							className={`speaker_photo ${
								item.performancegroupphoto_image && 'common-photo'
							}`}
							src={
								item.performancegroupphoto_image
									? item.performancegroupphoto_image
									: item.foto_image
							}
							alt=''
						/>
						<img className='speaker_logo' src={item.companylogo_image} alt='' />
						{speaker2.name !== '' && ( //condition if we have 2 speakers for 1 topic
							<div>
								<p className='speaker_name p1'>{speaker2.name}</p>
								<p className='speaker_position grey p2'>{speaker2.position}</p>
								<img className='speaker_logo' src={speaker2.logo} alt='' />
							</div>
						)}
					</div>
					{/* style={{ height: maxTitleHeight === 0 ? 'auto' : maxTitleHeight + 'px' }}  */}
					<div className='speaker_title'>
						<p className='speaker_title--content p1'>
							"{item.performancetitle_text}"
						</p>
						{/* {item.performancetitle_text &&
                        <>
                            <p className='speaker_title--content speaker_title--content--pc p1'>
                                "{item.performancetitle_text.substring(0, 25)}…"
                                <PopupMore btn_color={agenda.agendamarkercolor_text} quotes={true} video={item.video} desc2={item.professional_bio_text} title={item.performancetitle_text} title2='More about speaker' desc={item.performanceabstrakt_text} key={i} />
                            </p>
                            <p className='speaker_title--content speaker_title--content--mob p1'>
                                "{item.performancetitle_text.substring(0, 50)}…"
                                <PopupMore btn_color={agenda.agendamarkercolor_text} quotes={true} video={item.video} desc2={item.professional_bio_text} title={item.performancetitle_text} title2='More about speaker' desc={item.performanceabstrakt_text} key={i} />
                            </p>
                        </>
                    } */}
					</div>
					<div className='speaker_menu'>
						<Popup
							quotes={true}
							video={item.video}
							desc2={item.professional_bio_text}
							title={item.performancetitle_text}
							title2='More about speaker'
							desc={item.performanceabstrakt_text}
							key={i}
						/>
						<div
							onClick={() => toggleCalendars(item.fname_text + item.lname_text)}
							className={`calendars ${
								hideCalendars.length > 0 &&
								hideCalendars.indexOf(item.fname_text + item.lname_text) !==
									-1 &&
								'active'
							}`}
						>
							{dateStart !== '' && (
								<Calendars
									dateStart={dateStart}
									dateEnd={dateEnd}
									eventTitle={eventTitle}
								/>
							)}
						</div>
						<div
							onClick={() => toggleShares(item.fname_text + item.lname_text)}
							className={`speaker_menu--item ${
								hideShares.length > 0 &&
								hideShares.indexOf(item.fname_text + item.lname_text) !== -1 &&
								'active'
							}`}
						>
							<ShareLanding
								pageURL={pageURL}
								eventTitle={eventTitle}
								fname={item.fname_text}
								lname={item.lname_text}
								track={item.track}
								arrShares={hideShares}
							/>
						</div>
						<button
							onClick={() => toggleSocials(item.fname_text + item.lname_text)}
							className={`speaker_menu--item ${
								hideSocials.length > 0 &&
								hideSocials.indexOf(item.fname_text + item.lname_text) !== -1 &&
								'active'
							}`}
						>
							<SocialMedia
								speaker2={speaker2}
								item={item}
								hideSocials={hideSocials}
							/>
						</button>
					</div>
				</div>
			</React.Fragment>
		)
	);
};

export default SpeakerItem;
