import React, { useEffect, useState } from 'react';
import SpeakerItem from './SpeakerItem/SpeakerItem';
import './VideoPresent.css';

const VideoPresent = props => {
	const [hideSpeakers, setHideSpeakers] = useState(true);
	const [hideCalendars, setHideCalendars] = useState([]);
	const [hideShares, setHideShares] = useState([]);
	const [hideSocials, setHideSocials] = useState([]);
	const [mainColor, setMainColor] = useState(true);
	const [juniorTrack, setJuniorTrack] = useState([]);
	const [seniorTrack, setSeniorTrack] = useState([]);
	const [devopsTrack, setDevopsTrack] = useState([]);
	const [titleJuniorTrack, setTitleJuniorTrack] = useState('');
	const [titleSeniorTrack, setTitleSeniorTrack] = useState('');
	const [titleDevopsTrack, setTitleDevopsTrack] = useState('');
	const [juniorTopicsAmount, setJuniorTopicsAmount] = useState('');
	const [seniorTopicsAmount, setSeniorTopicsAmount] = useState('');
	const [devopsTopicsAmount, setDevopsTopicsAmount] = useState('');
	const [eventTitle, setEventTitle] = useState('');
	const [pageURL, setPageURL] = useState('');
	const [dateStart, setDateStart] = useState('');
	const [dateEnd, setDateEnd] = useState('');
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 920);

	const clearArr = () => {
		//function that hide popups for all menu btns
		document.querySelector('body').style.overflow = 'visible';
		setHideCalendars([]);
		setHideShares([]);
		setHideSocials([]);
	};
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	useEffect(() => {
		try {
			setTitleJuniorTrack(props.speakersJunior.response.trackName);
			setTitleSeniorTrack(props.speakersSenior.response.trackName);
			setTitleDevopsTrack(props.speakersDevops.response.trackName);
			setJuniorTopicsAmount(props.speakersJunior.response.trackTopicsAmount);
			setSeniorTopicsAmount(props.speakersSenior.response.trackTopicsAmount);
			setDevopsTopicsAmount(props.speakersDevops.response.trackTopicsAmount);
			setEventTitle(props.speakersSenior.response.eventName);
			setPageURL(props.speakersSenior.response.pageURL);
			setDateStart(props.speakersSenior.response.dateStart);
			setDateEnd(props.speakersSenior.response.dateEnd);
			// setDateStart(props.speakersSenior.response.dateStart.replace('A', 'T'));
			// setDateEnd(props.speakersSenior.response.dateEnd.replace('P', 'T'));
		} catch (e) {}
	}, [props.speakersSenior, props.speakersDevops, props.speakersJunior]);

	const toggleCalendars = name => {
		document.querySelector('body').style.overflow = 'hidden';
		if (hideCalendars.indexOf(name) > -1) {
			let index = hideCalendars.indexOf(name);
			if (index !== -1) {
				document.querySelector('body').style.overflow = 'visible';
				setHideCalendars(
					hideCalendars.filter(function (item) {
						return item !== name;
					})
				);
			}
		} else {
			setHideCalendars([...hideCalendars, name]);
		}
	};

	const toggleShares = name => {
		document.querySelector('body').style.overflow = 'hidden';
		if (hideShares.indexOf(name) > -1) {
			let index = hideShares.indexOf(name);
			if (index !== -1) {
				document.querySelector('body').style.overflow = 'visible';
				setHideShares(
					hideShares.filter(function (item) {
						return item !== name;
					})
				);
			}
		} else {
			setHideShares([...hideShares, name]);
		}
	};

	const toggleSocials = name => {
		document.querySelector('body').style.overflow = 'hidden';
		if (hideSocials.indexOf(name) > -1) {
			let index = hideSocials.indexOf(name);
			if (index !== -1) {
				document.querySelector('body').style.overflow = 'visible';
				setHideSocials(
					hideSocials.filter(function (item) {
						return item !== name;
					})
				);
			}
		} else {
			setHideSocials([...hideSocials, name]);
		}
	};

	useEffect(() => {
		document.addEventListener('click', () => {
			if (hideCalendars.length > 1) {
				clearArr();
			}
		});
	}, []);

	const toggleSpeakers = () => {
		setHideSpeakers(!hideSpeakers);
	};

	const unionSpeakers = track => {
		//function to get one object from 2 different Objects (schedule and speaker) from API request
		if (!Object.keys(track).length == 0) {
			//check if API request contains data
			for (let item of track.response.agenda_schedules) {
				//loop with agenda data
				for (let item2 of track.response.speakers) {
					//loop with speaker data
					if (item.performancespeaker_list_custom_speaker !== undefined) {
						//check if it's a block with topic of a speaker (we have in object other fields with Intro, Breaks, etc (we don't need it))
						if (
							item2._id === item.performancespeaker_list_custom_speaker[0] ||
							item2._id === item.performancespeaker_list_custom_speaker[1]
						) {
							//check the same speaker id for this topic
							Object.assign(item, item2); //get one object with topic's info and speaker's bio
						}
					}
				}
			}
		}
	};

	useEffect(() => {
		//function to change order of speakers for website due mentioned number for JUNIOR
		let newArr = [];
		if (!Object.keys(props.speakersJunior).length == 0) {
			//check if API request contains data
			for (let item of props.speakersJunior.response.agenda_schedules) {
				//loop with agenda data
				if (item.type_option_scheduletype === 'Performance') {
					newArr[item.performancespeakernum_number - 1] = item;
				}
			}
		}
		setJuniorTrack(newArr);
	}, [props.speakersJunior]);

	useEffect(() => {
		//function to change order of speakers for website due mentioned number for SENIOR
		let newArr = [];
		if (!Object.keys(props.speakersSenior).length == 0) {
			//check if API request contains data
			for (let item of props.speakersSenior.response.agenda_schedules) {
				//loop with agenda data
				if (item.type_option_scheduletype === 'Performance') {
					newArr[item.performancespeakernum_number - 1] = item;
				}
			}
		}
		setSeniorTrack(newArr);
	}, [props.speakersSenior]);

	useEffect(() => {
		//function to change order of speakers for website due mentioned number for SENIOR
		let newArr = [];
		if (!Object.keys(props.speakersDevops).length == 0) {
			//check if API request contains data
			for (let item of props.speakersDevops.response.agenda_schedules) {
				//loop with agenda data
				if (item.type_option_scheduletype === 'Performance') {
					newArr[item.performancespeakernum_number - 1] = item;
				}
			}
		}
		setDevopsTrack(newArr);
	}, [props.speakersDevops]);

	useEffect(() => {
		//set main color for SENIOR track
		if (Object.keys(props.speakersSenior).length !== 0) {
			setMainColor(props.speakersSenior.response.agenda.agendamarkercolor_text);
		}
	}, [props.speakersSenior]);

	const setMaxHeight = () => {
		let allTitleBlocks = document.querySelectorAll('.speaker_title');

		if (window.innerWidth > 768) {
			let maxHeight = 0;

			allTitleBlocks.forEach(item => {
				item.style.height = 'auto';
				if (item.offsetHeight > maxHeight) {
					maxHeight = item.offsetHeight;
				}
			});

			allTitleBlocks.forEach(item => {
				item.style.height = maxHeight + 'px';
			});
		} else {
			allTitleBlocks.forEach(item => {
				item.style.height = 'auto';
			});
		}
	};

	useEffect(() => {
		return () => {
			setMaxHeight();
		};
	});

	useEffect(() => {
		window.addEventListener('resize', () => {
			setMaxHeight();
		});
	}, []);

	return (
		<>
			{unionSpeakers(props.speakersDevops)}
			{unionSpeakers(props.speakersSenior)}
			{unionSpeakers(props.speakersJunior)}
			<div
				onClick={clearArr}
				className={`${
					hideCalendars.length > 0 ||
					hideShares.length > 0 ||
					hideSocials.length > 0
						? 'active'
						: ''
				} bg_blur`}
			></div>
			<div className='videoPresent container'>
				<h2 className='speakers_h2 colorful'>Our Speakers</h2>
				{!(
					Object.keys(props.speakersSenior).length === 0 &&
					Object.keys(props.speakersDevops).length === 0 &&
					Object.keys(props.speakersJunior).length === 0
				) && (
					<div className='video_block'>
						<div className='video_half devops'>
							<div className='video_header devops'>
								<h3 className='video_half--title'>{titleDevopsTrack} 🔥</h3>
								<h3 className='video_half--talks p1'>{devopsTopicsAmount}</h3>
							</div>
							<div className='speaker_items'>
								{!(Object.keys(props.speakersDevops).length === 0) &&
									devopsTrack.map((item, i) => (
										<React.Fragment key={`videoPresent_item-devops-${i}`}>
											<SpeakerItem
												trackTitle={titleDevopsTrack}
												dateEnd={dateEnd}
												dateStart={dateStart}
												pageURL={pageURL}
												eventTitle={eventTitle}
												toggleSocials={toggleSocials}
												toggleShares={toggleShares}
												toggleCalendars={toggleCalendars}
												hideSocials={hideSocials}
												hideShares={hideShares}
												hideCalendars={hideCalendars}
												hideSpeakers={hideSpeakers}
												item={item}
												i={i}
												track='devops'
												speakers={props.speakersDevops.response.speakers}
												agenda={props.speakersDevops.response.agenda}
											/>
										</React.Fragment>
									))}
							</div>
							{devopsTrack.length > (isMobile ? 4 : 8) && (
								<button className='video_btn button' onClick={toggleSpeakers}>
									{hideSpeakers ? 'Show more speakers' : 'Hide speakers'}
								</button>
							)}
						</div>
						<a className='button aftervideo_btn' href='#tickets'>
							REGISTER
						</a>
						<div
							className='video_half senior'
							style={{ borderColor: mainColor }}
						>
							<div
								className='video_header senior'
								style={{ borderColor: mainColor }}
							>
								<h3 className='video_half--title'>{titleSeniorTrack} 🔥</h3>
								<h3 className='video_half--talks p1'>{seniorTopicsAmount}</h3>
							</div>
							<div className='speaker_items'>
								{!(Object.keys(props.speakersSenior).length === 0) &&
									seniorTrack.map((item, i) => (
										<React.Fragment key={`videoPresent_item-senior-${i}`}>
											<SpeakerItem
												trackTitle={titleSeniorTrack}
												dateEnd={dateEnd}
												dateStart={dateStart}
												pageURL={pageURL}
												eventTitle={eventTitle}
												toggleSocials={toggleSocials}
												toggleShares={toggleShares}
												toggleCalendars={toggleCalendars}
												hideSocials={hideSocials}
												hideShares={hideShares}
												hideCalendars={hideCalendars}
												hideSpeakers={hideSpeakers}
												item={item}
												i={i}
												track='senior'
												speakers={props.speakersSenior.response.speakers}
												agenda={props.speakersSenior.response.agenda}
											/>
										</React.Fragment>
									))}
							</div>
							{seniorTrack.length > (isMobile ? 4 : 8) && (
								<button className='video_btn button' onClick={toggleSpeakers}>
									{hideSpeakers ? 'Show more speakers' : 'Hide speakers'}
								</button>
							)}
						</div>
						<a className='button aftervideo_btn' href='#tickets'>
							REGISTER
						</a>

						<div className='video_half junior'>
							<div className='video_header'>
								<h3 className='video_half--title'>{titleJuniorTrack} 🤓</h3>
								<h3 className='video_half--talks p1'>{juniorTopicsAmount}</h3>
							</div>
							<div className='speaker_items'>
								{juniorTrack
									.slice(
										0,
										hideSpeakers ? (isMobile ? 2 : 4) : juniorTrack.length
									)
									.map((item, i) => (
										<React.Fragment key={`videoPresent_item-junior-${i}`}>
											<SpeakerItem
												trackTitle={titleJuniorTrack}
												dateEnd={dateEnd}
												dateStart={dateStart}
												pageURL={pageURL}
												eventTitle={eventTitle}
												toggleSocials={toggleSocials}
												toggleShares={toggleShares}
												toggleCalendars={toggleCalendars}
												hideSocials={hideSocials}
												hideShares={hideShares}
												hideCalendars={hideCalendars}
												hideSpeakers={hideSpeakers}
												item={item}
												i={i}
												track='junior'
												speakers={props.speakersJunior.response.speakers}
												agenda={props.speakersJunior.response.agenda}
											/>
										</React.Fragment>
									))}
							</div>
							{juniorTrack.length > (isMobile ? 2 : 4) && (
								<button className='video_btn button' onClick={toggleSpeakers}>
									{hideSpeakers ? 'show more speakers' : 'Hide speakers'}
								</button>
							)}
						</div>
					</div>
				)}
				<p className='annonce'>More speakers to be announced soon</p>
				<a className='button aftervideo_btn' href='#tickets'>
					REGISTER
				</a>
			</div>
		</>
	);
};

export default VideoPresent;
