import React from 'react';
import './Numbers.css';


const Numbers = (props) => {
    const state = {
        Numbers_item: [
            {
                numb: '3000+',
                text: 'Registrations expected',
            },
            {
                numb: '20+',
                text: 'Speakers',
            },
            {
                numb: '10+',
                text: 'Hours of Deep Tech Talks',
            },
        ],
    };

    const renderNumbers_item = (item, i) => {
        return (
            <div className="numbers_item" key={`Numbers_item-${i}`}>
                <h2 className='numbers_item-numb'>{item.numb}</h2>
                <div className='numbers_hr'></div>
                <p className='numbers_item-text p1'>{item.text}</p>
            </div>
        );
    };

    return (
        <section className="numbers">
            <div className="container">
                {state.Numbers_item.map((item, i) => renderNumbers_item(item, i))}
            </div>
        </section>
    );
};

export default Numbers;