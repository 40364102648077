import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon'
import './index.css';
import App from './App';

function importAll(r) {
  let images = [];
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./img/favicon_gif', false, /\.(png|jpe?g|svg)$/));

ReactDOM.render(
  <React.StrictMode>
    <Favicon animationDelay={25} url={Object.values(images).map(item => item.default)} />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);