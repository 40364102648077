import "./OpenMenu.css";
import { Link } from "react-scroll";

const OpenMenu = (props) => {
  return (
		<>
			<div className='openMenu'>
				<ul>
					<li>
						<Link
							activeClass='active'
							to='home'
							spy={true}
							smooth={true}
							offset={0}
						>
							Home
						</Link>
					</li>
					<li>
						<Link
							onClick={props.close}
							activeClass='active'
							to='speakers'
							spy={true}
							smooth={true}
							offset={-94}
						>
							Speakers
						</Link>
					</li>
					{/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='agenda'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Agenda
            </Link>
          </li> */}
					{/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='workshops'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Workshops
            </Link>
          </li> */}
					<li>
						<Link
							onClick={props.close}
							activeClass='active'
							to='packages'
							spy={true}
							smooth={true}
							offset={-94}
						>
							Tickets
						</Link>
					</li>
					{/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='partners'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Partners
            </Link>
          </li> */}
					{/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='pc'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Committee
            </Link>
          </li> */}
					<li>
						<Link
							onClick={props.close}
							activeClass='active'
							to='sponsorship'
							spy={true}
							smooth={true}
							offset={-94}
						>
							For sponsors
						</Link>
					</li>
					<button className='header_register-mob'>
						<Link
							onClick={props.close}
							activeClass='active'
							to='packages'
							spy={true}
							smooth={true}
							offset={-94}
						>
							Register
						</Link>
					</button>
					{/* <li className='menu_pc--after'>
            <Link
              onClick={props.close}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Team
            </Link>
          </li> */}
				</ul>
			</div>
		</>
	);
};
export default OpenMenu;
