import React from 'react';
import './Audience.css';
import Marquee from 'react-double-marquee';

const Audience = (props) => {
    return (
        <section className="audience">
            <div className='audience_wrap container--full' style={{
                width: '100%',
                whiteSpace: 'nowrap',
            }}>
                <div className="audience_head container">
                    <h2 className="audience_title colorful">Who should attend</h2>
                </div>
                <div className="audience_content">
                    <Marquee direction='left' speed={0.03} delay={0} childMargin={0}>
                        <span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span>
                    </Marquee>
                    <Marquee direction='left' speed={0.02} delay={0} childMargin={0}>
                        <span className="orange">QA Engineers</span><span className="orange">&#x2022;</span><span className="orange">Test Developers</span><span className="orange">&#x2022;</span><span className="orange">QA Engineers</span><span className="orange">&#x2022;</span><span className="orange">Test Developers</span><span className="orange">&#x2022;</span><span className="orange">QA Engineers</span><span className="orange">&#x2022;</span><span className="orange">Test Developers</span><span className="orange">&#x2022;</span><span className="orange">QA Engineers</span><span className="orange">&#x2022;</span><span className="orange">Test Developers</span><span className="orange">&#x2022;</span><span className="orange">QA Engineers</span><span className="orange">&#x2022;</span><span className="orange">Test Developers</span><span className="orange">&#x2022;</span>
                    </Marquee>
                    <Marquee direction='left' speed={0.06} delay={0} childMargin={0}>
                        <span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span><span className="white">SDET</span><span className="white">&#x2022;</span><span className="white">Testers</span><span className="white">&#x2022;</span>
                    </Marquee>
                </div>
            </div>
        </section >
    );
};

export default Audience;