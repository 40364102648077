import AskCompany from './AskCompany/AskCompany';
import Enterprise from './Enterprise/Enterprise';
import Packages from './Packages/Packages';
import './PackagesWrap.css';
import Timer from './Timer/Timer';

const PackagesWrap = () => {
	return (
		<section id='tickets'>
			<div className='container'>
				{/* <h2> {'< Compare Packages >'}</h2> */}
					<AskCompany />
					<Enterprise />
				{/* <div className='tickets_container'>
					<Packages />
					<Timer />
				</div> */}
			</div>
		</section>
	);
};

export default PackagesWrap;
