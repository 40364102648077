import React from 'react';
import './Packages.css';
import PackagesInfofrom from './PackagesInfo.js';
import PackagesChild from './Track/Track.jsx';

const Packages = () => {
	let packagesElements = PackagesInfofrom.map((PackagesItem, i) => {
		return <PackagesChild key={`track_item-${i}`} track={PackagesItem} />;
	});
	return (
		<div className='packages' id='packages'>
			<div className='container'>
				<h2 className='packages_title'>
					{' '}
					<span>{'<'}</span> Packages <span>{'>'}</span>
				</h2>
				<div className='track_wrap'>{packagesElements}</div>
			</div>
		</div>
	);
};
export default Packages;
