import React, { useState, useEffect } from 'react';
import './Enterprise.css';
import close_popup from '../../../img/close_popup.svg'
import { send } from 'emailjs-com';

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [linkError, setLinkError] = useState(true);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                default:
            }
        }
    }, [value, validations]);

    useEffect(() => {
        if (isEmpty) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }

    }, [isEmpty])

    return {
        isEmpty
    }
}

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    const resetField = () => {
        setValue('');
        setDirty(false);
    }

    return {
        value,
        onChange,
        resetField,
        onBlur,
        isDirty,
        ...valid
    }
}

const Enterprise  = () => {
    const name = useInput('', { isEmpty: true });
    const phone = useInput('', { isEmpty: true });
    const company = useInput('', { isEmpty: true });
    const email = useInput('', { isEmpty: true });
    const event = document.title;
    const [letterSucces, setLetterSucces] = useState('');
    const [textErr, setTextErr] = useState('');
    const [showPopup, setShowPopup] = useState(false);


    const data = {
        name: name.value,
        phone: phone.value,
        company: company.value,
        email: email.value,
        event: event,
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        send(
            'service_9tfsft6',
            'template_ce0cbzk',
            data,
            'T8konufN4tNlYUdsh'
        )
            .then((response) => {
                setLetterSucces(true);
            })
            .catch((err) => {
                setLetterSucces(false);
                setTextErr(err);
            });
        setShowPopup(true);
        name.resetField();
        phone.resetField();
        email.resetField();
        company.resetField();
    }

	return (
        <div className='Enterprise'>
            <div className='ticket_form_head'>
                <h4>For Enterprise and Group Discount</h4>
                <p>Contact our Enterprise specialists</p>
            </div>
            <div className='ticket_form_body'>
                <form onSubmit={onSubmit}>
                    <input placeholder='Name*' required onChange={e => name.onChange(e)} onBlur={e => name.onBlur(e)} className={`form1__input ${(name.isDirty && name.isEmpty) && 'error'}`} value={name.value} type="text" name="name" />
                    <input placeholder='Phone*' required onChange={e => phone.onChange(e)} onBlur={e => phone.onBlur(e)} className={`form1__input ${(phone.isDirty && phone.isEmpty) && 'error'}`} value={phone.value} type="tel" name="phone" pattern="(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?"/>
                    <input placeholder='Email*' required onChange={e => email.onChange(e)} onBlur={e => name.onBlur(e)} className={`form1__input ${(email.isDirty && email.isEmpty) && 'error'}`} value={email.value} type="email" name="email" />
                    <input placeholder='Company*' required onChange={e => company.onChange(e)} onBlur={e => company.onBlur(e)} className={`form1__input ${(company.isDirty && company.isEmpty) && 'error'}`} value={company.value} type="text" name="company" />
                    <button className='ticket_form_btn'>SEND SECURE REQUEST</button>
                </form>
                {
                (letterSucces === true || letterSucces === false) && showPopup === true && <div className='form_popup form_message green' onClick={() => setShowPopup(false)}>
                    <div className='form_popup-modal'>
                        <img src={close_popup} className="form_popup-close" onClick={() => setShowPopup(false)} />
                        <p className='p1'>{letterSucces === true ? 'The form has been sent.' : `The email you sent could not be delivered. Error: ${textErr}`}</p>
                    </div>
                </div>
                }
            </div>
        </div>
	);
};

export default Enterprise;

