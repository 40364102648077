import React from 'react';
import './Popup.css';
import closeImg from './../../img/close_popup.svg'
import { Link } from "react-scroll";
import Popup from 'reactjs-popup';
import ReactPlayer from "react-player";

export default class Times extends React.Component {
    render() {
        return (
            <Popup
                trigger={<button className='popup_btn'>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.89044 18.3349H18.1093V8.3611L12.8642 3.11602H2.89044V18.3349ZM0.716309 20.509V0.941895H13.7611L20.2834 7.46427V20.509H0.716309ZM5.06456 16.1608H15.9352V13.9867H5.06456V16.1608ZM5.06456 11.8125H15.9352V9.6384H5.06456V11.8125ZM5.06456 7.46427H12.674V5.29015H5.06456V7.46427ZM2.89044 18.3349V3.11602V18.3349Z" fill="#A7A7A7" />
                    </svg>
                    Abstract </button>}
                modal
                nested
                onOpen={() => { document.querySelector('html').style.setProperty('overflow', 'hidden'); document.querySelector('html').style.setProperty('position', 'relative'); document.querySelector('html').style.setProperty('height', '100%') }}
                onClose={() => { document.querySelector('html').style.setProperty('overflow', 'auto') }}
            >
                {close => (
                    <>
                        <div className="modal">
                            <img src={closeImg} className="close" onClick={close} />
                            <h4 className='popup_title'>{this.props.quotes && '"'}{this.props.title}{this.props.quotes && '"'}</h4>
                            {this.props.video && <ReactPlayer
                                url={this.props.video}
                                width="100%"
                                height="100%"
                                controls
                                playIcon={<button className='videoPresent_item-play'></button>}
                                className='popup_video'
                            />}
                            <p className='popup_abstract'>{this.props.desc}</p>
                            {this.props.desc2 && <><h4 className='popup_title'>{this.props.title2}</h4>
                                <p className='popup_abstract'>{this.props.desc2}</p></>}
                            <Link
                                activeClass="active"
                                to='tickets'
                                spy={true}
                                smooth={true}
                                offset={-10}
                                className='popup_inside_btn button'
                                onClick={close}
                            >
                                Get ticket
                            </Link>
                        </div>
                    </>
                )}
            </Popup>
        )
    }
}