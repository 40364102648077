import moment from 'moment';
import React from 'react';
import { Link } from 'react-scroll';
import close from '../../img/close_popup.svg';
import './CountDown.css';
import CountDown from './CountDown/CountDown.jsx';

export default class Countdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCountdown: true,
			deadLine: moment.utc('2024-12-25T10:00:00+03:00'),
			scroll: 0,
			packages: [
				{
					price: ``,
					title: '🔥 Christmas Sale -50% 🔥',
				},
				{
					price: ``,
					title: '🔥 Christmas Sale -50% 🔥',
				},
			],
			activePackage: 0,
		};

		setInterval(() => {
			let myDate = moment();
			if (myDate.isSameOrAfter(this.state.deadLine)) {
				this.setState({
					deadLine: this.state.deadLine.add(7, 'days'),
				});
			}
		}, 1000);

		this.pageLink = window.location.href.toString();
		this.indexUtm = this.pageLink.indexOf('?');
		this.utm = this.pageLink.slice(this.indexUtm + 1);

		this.showCountdown = this.showCountdown.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.myCallback = this.myCallback.bind(this);
	}

	// Утилита для добавления ведущего нуля
	formatNumber(number) {
		return number < 10 ? `0${number}` : number;
	}

	myCallback() {
		if (this.state.activePackage < 1) {
			this.setState({
				activePackage: this.state.activePackage + 1,
			});
		} else {
			this.setState({
				activePackage: 0,
			});
		}
	}

	showCountdown() {
		this.setState({
			isCountdown: !this.state.isCountdown,
		});
	}

	handleScroll() {
		this.setState({
			scroll: window.scrollY,
		});
	}

	componentDidMount = () => {
		setInterval(this.myCallback, 3500);
	};

	componentWillMount = () => {
		window.addEventListener('scroll', this.handleScroll);
		return () => window.removeEventListener('scroll', this.handleScroll);
	};

	render() {
		return (
			<React.Fragment>
				{moment().isBefore(this.state.deadLine) && this.state.scroll > 500 && (
					<div>
						{this.state.isCountdown && (
							<div className='countdown-wrap'>
								<div id='countdown' className='countdown'>
									<div className='countdown-content-block'>
										<div className='countdown_text_block'>
											<p className='countdown-descr'>
												{this.state.packages[this.state.activePackage].title}
											</p>
											{/* <p className='countdown-descr'>
												{this.state.packages[this.state.activePackage].price}
											</p> */}
										</div>
										<div className='countdown-main'>
											<CountDown
												futureDate={this.state.deadLine}
												formatNumber={this.formatNumber}
											></CountDown>
										</div>
										<Link
											activeClass='active'
											to='tickets'
											spy={true}
											smooth={true}
											offset={0}
											className='countdown_btn'
										>
											{`get ticket`}
										</Link>
									</div>
									<img
										onClick={this.showCountdown}
										className='close_countdown'
										src={close}
										alt='X'
									/>
								</div>
							</div>
						)}
					</div>
				)}
			</React.Fragment>
		);
	}
}
