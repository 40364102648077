let PackagesItem = [
	{
		track: 'Junior',
		trackJunior: true,
		trackTitle: 'Junior Track',
		trackSubtitle: 'ticket',
		trackPrice: '0',
		runningString: true,
		trackButton: 'Register',
		trackLink: 'https://geekle.us/ticketfree/1721377925203x323243337885941760',
		// juniorTicket1Name: "Free Ticket",
		// juniorTicket1Price: "0.00",
		// juniorTicket1Link: "https://geekle.us/ticketfree/1684419531109x998420949885255700",
		// juniorTicket2Name: "+ Certificate",
		// juniorTicket2Price: "9.99",
		// juniorTicket2Link: "https://geekle.us/ticket/1699051262272x434038936238293000",
		// juniorTicket3Name: "+ Certificate & Recordings",
		// juniorTicket3Price: "15.99",
		// juniorTicket3Link: "https://geekle.us/ticket/1699052276762x800257793798176800",
	},
	{
		track: 'Senior',
		trackFull: true,
		trackAI: false,
		trackTitle: 'Senior Day',
		trackSubtitle: 'ticket',
		runningString: true,
		trackPrice: '99',
		// trackPriceOld: '199',
		paddingT: true,
		// earlyBird: 'early birds price',
		trackDesc: '1',
		trackButton: 'Register',
		trackList1: 'DAY1 Access',
		trackLink: 'https://geekle.us/ticket/1721377730369x309848772851793900',
		// blackFriday: true,
	},
	{
		track: 'AI_Agent',
		trackFull: true,
		trackAI: true,
		trackTitle: 'AI Agent Day',
		trackSubtitle: 'ticket',
		runningString: true,
		trackPrice: '99',
		// trackPriceOld: '199',
		paddingT: true,
		// earlyBird: 'early birds price',
		trackDesc: '1',
		trackButton: 'Register',
		trackList1: 'DAY1 Access',
		trackLink: 'https://geekle.us/ticket/1733944824461x240617458317393920',
		// blackFriday: true,
	},
	{
		track: 'Combo',
		trackFull: true,
		trackAI: false,
		trackCombo: true,
		trackTitle: 'Combo 🔥',
		trackSubtitle: 'Senior Day + AI Agent Day',
		runningString: true,
		trackPrice: '149',
		// trackPriceOld: '199',
		paddingT: true,
		// earlyBird: 'early birds price',
		trackDesc: '1',
		trackButton: 'Register',
		trackList1: 'DAY1 Access',
		trackLink: 'https://geekle.us/ticket/1733945366068x115269238763814910',
		// blackFriday: true,
	},
	// {
	// 	track: 'Full Access',
	//     trackCombo: true,
	//     trackTitle: "JS Combo",
	//     trackSubtitle: "package",
	//     runningString: true,
	//     trackPrice: "179",
	//     // earlyBird: "early birds price",
	//     trackDesc: "1",
	//     trackButton: "Register",
	//     trackLink: 'https://geekle.us/ticket/1724658118123x628582870531440600',
	// },
	{
		track: 'Year',
		trackTitle: 'YEAR OF QA ❤️',
		trackSubtitle: 'package',
		runningString: true,
		trackPrice: '249',
		// trackPriceOld: '349',
		earlyBird: 'one year access',
		trackDesc: '1',
		trackText: true,
		yearTrackText: true,
		trackText1: 'One Year Package',
		trackButton: 'Register',
		trackLink: 'https://geekle.us/ticket/1721377629301x744316922489733100',
		// blackFriday: true,
	},
	{
		newPremium: true,
		trackTitle: 'GEEKLE PREMIUM',
		trackSubtitle: 'MEMBERSHIP',
		trackPriceApperance: 2,
		// trackFullPrice: "399",
		trackPrice: '399',
		// trackPriceOld: '399',
		trackText: true,
		trackText1: 'One Year Package',
		trackDesc: '1',
		trackButton: 'BUY',
		trackLink: 'https://geekle.us/ticket/1721288484155x292078953995960300',
		inverseColors: true,
		// blackFriday: true,
	},
	// {
	// 	trackTitle: 'UNLIMITED',
	// 	trackSubtitle: 'YEAR ENTERPRISE PACKAGE',
	// 	trackPriceApperance: 4,
	// 	runningString: true,
	// 	trackPrice: 'Ask Our Manager',
	// 	trackText: true,
	// 	trackText1: 'One Year Pass for up to 100 members',
	// 	trackDesc: '1',
	// 	trackButton: 'Get Offer',
	// 	trackLink: 'enterprise',
	// 	validDate: '9.06',
	// },
];

export default PackagesItem;
