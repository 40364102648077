import React from 'react';
import './LatestProduct.css'
import left from '../../img/latest_product/items-l.png'
import right from '../../img/latest_product/items-r.png';


const LatestProduct = () => {
	return (
		<section className='latest_product'>
			<div className='container'>
				<div className='latest_title-wrap'>
					<h3 className='latest_title'>
						{'{ AI Agents Day: AI Tools for Quality }'}
					</h3>
					<span className='latest_title-date'>February 4, Online</span>
				</div>
				<div className='latest_content'>
					<div className='latest_item latest_item-left'>
						AI Agents in Quality: Overview
					</div>
					<div className='latest_items-center'>
						<img src={left} alt='left' />
						<div className='latest_items-center--l'>
							<div className='latest_item'>Agents for CI/ CD Pipelines</div>
							<div className='latest_item'>AI-Augmented Testing</div>
							<div className='latest_item'>Vision Agents in Quality</div>
							<div className='latest_item'>Amazon Q</div>
						</div>
						<div className='latest_items-center--r'>
							<div className='latest_item'>ML for Test Automation</div>
							<div className='latest_item'>AI for Test Maintenance</div>
							<div className='latest_item'>TBA</div>
							<div className='latest_item'>TBA</div>
						</div>
						<img src={right} alt='right' />
					</div>
					<div className='latest_item latest_item-right'>
						AI Tool Set for Assisted QA
					</div>
				</div>
			</div>
		</section>
	);
};

export default LatestProduct;
