import React from 'react';
import './Speakers from.css';
import ebay from '../../img/SF/ebay.png';
// import Facebook from "../../img/SF/facebook.png";
import apiumhub from '../../img/SF/apiumhub.svg';
import Vimeo from '../../img/SF/Vimeo.png';
import capco from '../../img/SF/capco.png';
import Saucelabs from '../../img/SF/Saucelabs.png';
import RedHat from '../../img/SF/Red Hat.png';
import testai from '../../img/SF/testai.png';
import Vaisala from '../../img/SF/Vaisala.png';
import Perfecto from '../../img/SF/perfecto.png';
import Capgemini from '../../img/SF/Capgemini.png';
import Paylocity from '../../img/SF/Paylocity.png';
import ISTQB from '../../img/SF/ISTQB.png';
import Udemy from '../../img/SF/Udemy.png';
import Applitools from '../../img/SF/Applitools.png';
import Raiffeisen from '../../img/SF/Raiffeisen Bank.png';

const SpeakersFrom = () => {
	const state = {
		Speakers_item: [
			{
				img: apiumhub,
				alt: 'apiumhub logo',
			},
			{
				img: Udemy,
				alt: 'Udemy',
			},
			{
				img: ebay,
				alt: 'ebay',
			},
			{
				img: Vimeo,
				alt: 'Vimeo',
			},
			{
				img: Capgemini,
				alt: 'Capgemini',
			},
			{
				img: Saucelabs,
				alt: 'Saucelabs',
			},
			{
				img: RedHat,
				alt: 'RedHat',
			},
			{
				img: Applitools,
				alt: 'Applitools',
			},
			{
				img: Perfecto,
				alt: 'Perfecto',
			},
			{
				img: testai,
				alt: 'test.ai',
			},
			{
				img: Vaisala,
				alt: 'Vaisala',
			},
			{
				img: capco,
				alt: 'capco',
			},
			{
				img: Paylocity,
				alt: 'Paylocity',
			},
			{
				img: ISTQB,
				alt: 'ISTQB',
			},
			{
				img: Raiffeisen,
				alt: 'Raiffeisen',
			},
		],
	};

	const renderSpeakers_item = (item, i) => {
		return (
			<div className='logo_item speakers_from_item' key={`Numbers_item-${i}`}>
				<img src={item.img} alt={item.alt} />
			</div>
		);
	};

	return (
		<section className='speakers_from_wrap red_grad'>
			<div className='container'>
				<h2>Speakers in our community</h2>
				<div className='speakers_from'>
					{state.Speakers_item.map((item, i) => renderSpeakers_item(item, i))}
				</div>
			</div>
		</section>
	);
};

export default SpeakersFrom;
