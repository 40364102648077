import React, { useEffect } from "react";
import AddToCalendar from "@culturehq/add-to-calendar";
import '@culturehq/add-to-calendar/dist/styles.css';

const Calendars = ({ eventTitle, dateStart, dateEnd }) => {
    useEffect(() => {
        const svgCalendar = document.querySelectorAll('.chq-atc--button');
        svgCalendar.forEach(item => {
            item.innerHTML = `<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 20.5088V2.50879H3.5V0.508789H5.5V2.50879H13.5V0.508789H15.5V2.50879H18.5V20.5088H0.5ZM2.5 18.5088H16.5V8.50879H2.5V18.5088ZM2.5 6.50879H16.5V4.50879H2.5V6.50879ZM2.5 6.50879V4.50879V6.50879Z" fill="#A7A7A7" />
          </svg>
          <span>Add</span>`;
        })
    });

    return (
        <AddToCalendar
            event={{
                name: eventTitle,
                details: "",
                location: "London",
                startsAt: dateStart,
                endsAt: dateEnd
            }}
            children='Add'
        />
    )
}

export default Calendars;
