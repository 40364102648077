import React from 'react';
import './Running_string.css';
import Marquee from 'react-double-marquee';

const Running_string = () => {
    return (
        <div className="running_string">
            <div className='audience_wrap' style={{
                width: '100%',
                whiteSpace: 'nowrap',
            }}>
                <Marquee direction='left' speed={0.08} delay={0} childMargin={0}>
                    <span className="white"> QA Global Summit'25</span><span className="red">&#x2022;</span><span className="white"> QA Global Summit'25</span><span className="red">&#x2022;</span><span className="white"> QA Global Summit'25</span><span className="red">&#x2022;</span><span className="white"> QA Global Summit'25</span><span className="red">&#x2022;</span><span className="white"> QA Global Summit'25</span><span className="red">&#x2022;</span><span className="white"> QA Global Summit'25</span><span className="red">&#x2022;</span>
                </Marquee>
            </div>
        </div>
    );
};

export default Running_string;